import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CustomSanityImage = ({ imageData, newProjectId = "egmrdv6e", oldProjectId = "nikffqne", objectFit = 'cover' }) => {
    const image = getImage(imageData?.asset?.gatsbyImageData);

    const modifyImageUrls = (imageData) => {
        if (!imageData) return imageData;

        // Clone the image data to avoid direct mutations
        let modifiedImageData = JSON.parse(JSON.stringify(imageData));

        // Replace the project ID in the src URL
        if (modifiedImageData.images.fallback.src.includes(oldProjectId)) {
            modifiedImageData.images.fallback.src = modifiedImageData.images.fallback.src.replace(oldProjectId, newProjectId);
        }
        if (modifiedImageData.images.fallback.srcSet && modifiedImageData.images.fallback.srcSet.includes(oldProjectId)) {
            modifiedImageData.images.fallback.srcSet = modifiedImageData.images.fallback.srcSet.replace(new RegExp(oldProjectId, 'g'), newProjectId);
        }

        return modifiedImageData;
    };

    const modifiedImageData = modifyImageUrls(image);

    return <GatsbyImage objectFit={objectFit} className={"h-full w-full"} image={modifiedImageData} alt={imageData?.alt} />;
};

export default CustomSanityImage;
